<template>
	<div id="pageContent">

		<div id="title" style="margin: 10px;">
			<p>
				<span>示例：</span>
			<pre>
69414068756xx
69414068756xx
69414068756xx
			</pre>
			</p>

			<div>
				<textarea v-model="goodsCodeS" rows="15" cols="200"></textarea>
				<p>
					<el-button @click="exportGoodsList" type="primary">导出</el-button>
				</p>
			</div>
		</div>


	</div>
</template>


<script>
	export default {
		data() {
			return {
				goodsCodeS: ""
			}
		},
		methods: {
			exportGoodsList() {
				let goodsArr = this.goodsCodeS.split("\n")
				if (goodsArr.length > 1) {
					let goods = [];
					for (let i = 0; i < goodsArr.length; i++) {
						goods.push(goodsArr[i])
					}
					let postData = {
						"goods": goods
					}
					
					this.$axios.post("/a1/goods/export/wtm", postData).then((response) => {
						let key = response.data.data.to_key;
						let dowUrl = this.$root.DownUrl;
						//window.open();
						location.href = dowUrl + "/c1/down/" + key
					});
				} else {
					this.$message.error("请填写需要导出的产品sku");
				}
				// let dowUrl = this.$root.DownUrl;
				// window.open(dowUrl + "/a1/goods/exportExcel?job=" + strto);
			}
		},

	}
</script>

<style>
</style>
